.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  background: #ffffff;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.section__q3E45 {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  background: #1f1f1f;
  padding-bottom: 0px;
  justify-self: flex-start;
  margin-bottom: 0px;
  height: 148px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__q3E45 > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__q3E45 > * {
    grid-column: 4;
  }
}
.navigationBar:global(.__wab_instance) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  background: none;
  margin-top: 0px;
  justify-self: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  height: 104px;
  font-weight: 600;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  padding: 16px;
}
.link__ixbXb {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.text__avtod {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 39px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  margin-right: 0px;
  min-width: 0;
  padding: 0px;
}
.img__dbP9W {
  object-fit: cover;
  max-width: 67px;
  width: 100%;
  height: 32px;
  min-width: 0;
}
.img__dbP9W > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__dbP9W {
    width: 30px;
    height: 30px;
  }
}
.img__m4Ntl {
  object-fit: cover;
  max-width: 35px;
  width: 100%;
  min-width: 0;
}
.img__m4Ntl > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__m4Ntl {
    width: 30px;
    height: 30px;
  }
}
.link___6ZjbA {
  position: relative;
  display: flex;
  background: none;
  margin-top: 0px;
}
@media (min-width: 768px) {
  .link___6ZjbA {
    max-width: 141px;
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
    min-width: 0;
  }
}
.talkToUsButton:global(.__wab_instance) {
  max-width: 100%;
  transition-property: all;
  transition-duration: 1s;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  width: 184px;
  margin-right: 0px;
  margin-left: 0px;
  height: 55px;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 768px) {
  .talkToUsButton:global(.__wab_instance) {
    margin-right: 0px;
    margin-left: 0px;
    height: 50px;
    width: 131px;
    flex-shrink: 0;
  }
}
.svg__wgkQk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___80Yru {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  color: #000000f7;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
@media (min-width: 768px) {
  .text___80Yru {
    font-size: 20px;
  }
}
.svg___91QEi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.section__zKc6A {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  background: #1f1f1f;
  height: 847px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__zKc6A > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__zKc6A {
    height: 454px;
  }
}
@media (min-width: 768px) {
  .section__zKc6A > * {
    grid-column: 4;
  }
}
.freeBox__kTQzQ {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: 459px;
  max-width: 100%;
  justify-self: flex-start;
  margin-bottom: 0px;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__kTQzQ {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 0px;
    margin-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    width: auto;
    justify-self: flex-start;
    height: 100%;
    min-height: 0;
  }
}
.freeBox__gPldX {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0px;
  min-width: 0;
  padding: 8px 8px 50px;
}
.freeBox__lvNGr {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 0px;
}
.freeBox__lvNGr > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__lvNGr > :global(.__wab_flex-container) > *,
.freeBox__lvNGr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lvNGr > :global(.__wab_flex-container) > picture > img,
.freeBox__lvNGr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.text__sJgNm {
  font-size: 60px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: var(--token-DmLrji51i2Kn);
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.span {
  font-family: "Inter", sans-serif;
  font-size: 60px;
  font-weight: 800;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding-right: 0px;
}
.text__e94Vv {
  font-size: 22px;
  text-align: center;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #ffffffd9;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
@media (min-width: 768px) {
  .text__e94Vv {
    margin-bottom: 21px;
    padding-right: 0px;
  }
}
.freeBox__lj7An {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__lj7An > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__lj7An > :global(.__wab_flex-container) > *,
.freeBox__lj7An > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lj7An > :global(.__wab_flex-container) > picture > img,
.freeBox__lj7An
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.textInput2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 199px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .textInput2:global(.__wab_instance) {
    width: 187px;
    flex-shrink: 0;
  }
}
.svg__upUHg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___68Xoj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.link__kNeqG {
  position: relative;
  display: flex;
}
.button2__i8Aw2:global(.__wab_instance) {
  max-width: 100%;
  width: 134px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .button2__i8Aw2:global(.__wab_instance) {
    width: 113px;
    flex-shrink: 0;
  }
}
.svg__kRm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nJbl4 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #000000;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  padding-left: 0px;
  max-width: 117px;
  width: 100%;
  min-width: 0;
}
.svg__r9Hf5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__tc7J {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.img__dbj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 285px;
  margin-left: 0px;
  width: 379px;
  flex-shrink: 0;
}
.img__dbj > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__dbj {
    margin-left: 0px;
    height: 425px;
    width: 506px;
    margin-bottom: 0px;
    flex-shrink: 0;
  }
}
.section__i7PBw {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0px;
  background: #1f1f1f;
  padding-top: 0px;
  justify-self: flex-start;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__i7PBw > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__i7PBw {
    padding-top: 0px;
  }
}
@media (min-width: 768px) {
  .section__i7PBw > * {
    grid-column: 4;
  }
}
.h1__zXBrR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 24px;
  font-size: 40px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #ffffff;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  margin-top: 17px;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1__zXBrR {
    font-size: 50px;
    height: auto;
  }
}
.youTube:global(.__wab_instance) {
  width: 640px;
  height: 390px;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.section__dzolr {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  margin-top: 0px;
  justify-self: flex-start;
  background: #1f1f1f;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 40px 0 65px;
}
.section__dzolr > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__dzolr {
    margin-top: 0px;
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .section__dzolr > * {
    grid-column: 4;
  }
}
.text__rszkJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 46px;
  text-align: center;
  color: #ffffff;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 25px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__rszkJ {
    font-size: 40px;
  }
}
.freeBox__clMG {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__clMG {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.freeBox__m8Hbj {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 0px 8px 8px;
}
@media (min-width: 768px) {
  .freeBox__m8Hbj {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.freeBox___6W3Co {
  position: relative;
  width: auto;
  height: auto;
  max-width: 50%;
  display: none;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___6W3Co {
    display: block;
  }
}
.img__pxT4Z {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  object-position: center center;
  left: 22px;
  top: 44px;
  width: auto;
  height: auto;
  max-height: 100%;
  bottom: 57px;
  right: 27px;
  margin-left: 0px;
  flex-grow: 0;
}
.img__pxT4Z > picture > img {
  object-fit: cover;
  object-position: center center;
}
.freeBox__gop0S {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___3QtRa {
  position: relative;
  object-fit: cover;
  max-width: 20%;
  align-self: flex-start;
}
.img___3QtRa > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img___3QtRa {
    display: none !important;
  }
}
.text__yaxM {
  position: relative;
  width: 459px;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
@media (min-width: 768px) {
  .text__yaxM {
    font-size: 22px;
  }
}
.text__aIUxt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__aIUxt {
    font-size: 20px;
  }
}
.freeBox___5Yxqx {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___5Yxqx {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.freeBox__ieqLs {
  position: relative;
  width: auto;
  height: auto;
  max-width: 50%;
  display: none;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__ieqLs {
    display: block;
  }
}
.img___2N4Q {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  object-position: center center;
  left: 21px;
  top: 60px;
  right: 21px;
  bottom: 64px;
  width: auto;
  height: auto;
  max-height: 100%;
}
.img___2N4Q > picture > img {
  object-fit: cover;
  object-position: center center;
}
.freeBox__ydim3 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__ihtYh {
  position: relative;
  object-fit: cover;
  max-width: 20%;
  align-self: flex-start;
}
.img__ihtYh > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__ihtYh {
    display: none !important;
  }
}
.text___34Z9H {
  position: relative;
  width: 430px;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  font-family: "Outfit", sans-serif;
  margin-left: 0px;
  padding-left: 0px;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
@media (min-width: 768px) {
  .text___34Z9H {
    display: none;
  }
}
.text__ve8Ox {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__ve8Ox {
    display: none;
  }
}
.text__r1Zvz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 800;
  font-size: 17px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__r1Zvz {
    font-size: 22px;
  }
}
.text__uqxuR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__uqxuR {
    font-size: 20px;
  }
}
.freeBox___3Sgej {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___3Sgej {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.freeBox__cLsVj {
  position: relative;
  width: auto;
  height: auto;
  max-width: 50%;
  display: none;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__cLsVj {
    display: block;
  }
}
.img__lD9Nx {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  object-position: center center;
  left: 27px;
  top: 43px;
  right: 21px;
  bottom: 58px;
  width: auto;
  height: auto;
  max-height: 100%;
}
.img__lD9Nx > picture > img {
  object-fit: cover;
  object-position: center center;
}
.freeBox__kauQ8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__eqAz1 {
  position: relative;
  object-fit: cover;
  max-width: 20%;
  align-self: flex-start;
}
.img__eqAz1 > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__eqAz1 {
    display: none !important;
  }
}
.text___5XdoX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text___5XdoX {
    display: none;
  }
}
.text__nLbJf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__nLbJf {
    display: none;
  }
}
.text__fbEg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 800;
  font-size: 17px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__fbEg {
    font-size: 22px;
  }
}
.text___619H6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text___619H6 {
    font-size: 20px;
  }
}
.freeBox__a0Zsq {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__a0Zsq {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.freeBox___48DpB {
  position: relative;
  width: auto;
  height: auto;
  max-width: 50%;
  display: none;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___48DpB {
    display: block;
  }
}
.img__rt0Pp {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  object-position: center center;
  left: 22px;
  top: 49px;
  width: auto;
  height: auto;
  max-height: 100%;
  bottom: 49px;
  right: 20px;
}
.img__rt0Pp > picture > img {
  object-fit: cover;
  object-position: center center;
}
.freeBox___3Kfnw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___3MZz {
  position: relative;
  object-fit: cover;
  max-width: 20%;
  align-self: flex-start;
}
.img___3MZz > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img___3MZz {
    display: none !important;
  }
}
.text__bKaCg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__bKaCg {
    display: none;
  }
}
.text__tx5B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-size: 18px;
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__tx5B {
    display: none;
  }
}
.text___7Ybmx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 800;
  font-size: 17px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text___7Ybmx {
    font-size: 22px;
  }
}
.text___5Ro7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text___5Ro7 {
    font-size: 20px;
  }
}
.section__bE7Om {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  margin-top: 0px;
  justify-self: flex-start;
  background: #ffffff;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 40px 0 65px;
}
.section__bE7Om > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__bE7Om {
    margin-top: 0px;
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .section__bE7Om > * {
    grid-column: 4;
  }
}
.text__yw1A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 46px;
  text-align: center;
  color: #000000;
  font-weight: 800;
  font-size: 26px;
  margin-bottom: 25px;
  justify-self: flex-start;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
}
@media (min-width: 768px) {
  .text__yw1A {
    font-size: 40px;
    font-weight: 700;
  }
}
.freeBox__c34ZL {
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__c34ZL {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.freeBox___4KGrG {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #f2fbff;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 8px 8px 10px;
}
.img__nkKmQ {
  object-fit: cover;
  max-width: 25.14%;
  position: relative;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
}
.img__nkKmQ > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__nkKmQ {
    max-width: 53.92%;
    width: 100%;
    height: 149px;
    min-width: 0;
    flex-shrink: 0;
    display: none !important;
  }
}
.img__p80 {
  object-fit: cover;
  max-width: 50%;
  position: relative;
  align-self: flex-start;
}
.img__p80 > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__p80 {
    max-width: 53.92%;
    width: 100%;
    height: 149px;
    min-width: 0;
    flex-shrink: 0;
  }
}
.text__pkJzo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 600;
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__pkJzo {
    font-size: 26px;
  }
}
.text__s9Fv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__s9Fv {
    font-size: 20px;
  }
}
.freeBox__sNxW7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #f2fbff;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 8px 8px 10px;
}
.img__ryziM {
  position: relative;
  object-fit: cover;
  max-width: 25.14%;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
}
.img__ryziM > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__ryziM {
    margin-right: 0px;
    max-width: 25.14%;
    width: 100%;
    min-width: 0;
    display: none !important;
  }
}
.img__n3Vnf {
  position: relative;
  object-fit: cover;
  max-width: 50%;
  align-self: flex-start;
}
.img__n3Vnf > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__n3Vnf {
    margin-right: 0px;
    max-width: 63.48%;
    width: 100%;
    min-width: 0;
  }
}
.text___5GVH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 600;
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text___5GVH {
    font-size: 26px;
  }
}
.text__ieSjW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__ieSjW {
    font-size: 20px;
    padding-left: 0px;
  }
}
.freeBox__sTTwt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #f2fbff;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 8px 8px 10px;
}
.img__lnCPj {
  position: relative;
  object-fit: cover;
  max-width: 25.14%;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
}
.img__lnCPj > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__lnCPj {
    max-width: 25.14%;
    width: 100%;
    min-width: 0;
    display: none !important;
  }
}
.img__nVrBj {
  position: relative;
  object-fit: cover;
  max-width: 50%;
  align-self: flex-start;
}
.img__nVrBj > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__nVrBj {
    max-width: 63.48%;
    width: 100%;
    height: 166px;
    min-width: 0;
    flex-shrink: 0;
  }
}
.text__p5Uhy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 600;
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__p5Uhy {
    font-size: 26px;
  }
}
.text__dbgpe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__dbgpe {
    font-size: 20px;
  }
}
.freeBox__j6974 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #f2fbff;
  min-width: 0;
  border-radius: 10px;
  padding: 8px 8px 8px 10px;
}
.img__aVr56 {
  position: relative;
  object-fit: cover;
  max-width: 25.14%;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
}
.img__aVr56 > picture > img {
  object-fit: cover;
}
@media (min-width: 768px) {
  .img__aVr56 {
    max-width: 63.48%;
    width: 100%;
    min-width: 0;
  }
}
.text__vmH6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-weight: 600;
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__vmH6 {
    font-size: 26px;
  }
}
.text__dHlgz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #000000;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__dHlgz {
    font-size: 20px;
  }
}
.section__lwVpj {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0px;
  padding-right: 0;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__lwVpj > * {
  grid-column: 4;
}
.freeBox___1F2F6 {
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  width: 100%;
  background: #ffffff;
  margin-top: 0px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 54px 16px;
}
.freeBox___1F2F6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox___1F2F6 > :global(.__wab_flex-container) > *,
.freeBox___1F2F6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1F2F6 > :global(.__wab_flex-container) > picture > img,
.freeBox___1F2F6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.freeBox__dInGe {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 1080px;
  background: #1f1f1f;
  border-radius: 10px;
  padding: 40px 0px;
}
.freeBox__dInGe > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__dInGe > :global(.__wab_flex-container) > *,
.freeBox__dInGe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dInGe > :global(.__wab_flex-container) > picture > img,
.freeBox__dInGe
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .freeBox__dInGe {
    padding-right: 149px;
    padding-left: 151px;
    max-width: 879px;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .freeBox__dInGe > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.text___7TcPl {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 800;
  font-style: normal;
  color: #ffffffd9;
  text-align: center;
  text-transform: none;
  line-height: 1;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding-bottom: 0px;
}
@media (min-width: 768px) {
  .text___7TcPl {
    font-size: 48px;
    padding-right: 8px;
    padding-left: 8px;
  }
}
.text__iLhBg {
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: var(--token-15STFHRE3MM_);
  font-weight: 400;
  font-style: normal;
  color: #ffffffd9;
  text-align: center;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding-right: 0px;
}
@media (min-width: 768px) {
  .text__iLhBg {
    text-align: center;
    font-size: 20px;
    padding-right: 8px;
    padding-left: 8px;
  }
}
.freeBox__xEQiL {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.textInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-right: 21px;
  width: 208px;
  height: 46px;
  flex-shrink: 0;
}
.svg__zzRzz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4JkKx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.link__ji1Ba {
  position: relative;
  display: flex;
  color: #0070f3;
  background: none;
}
.button2__zPk3Q:global(.__wab_instance) {
  max-width: 100%;
}
.svg__g5Cry {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__h1G3W {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #000000;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  margin-bottom: 0px;
}
.svg__cZpMm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.section__e8QNy {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 38px;
  margin-top: 0px;
  background: #ffffff;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__e8QNy > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__e8QNy > * {
    grid-column: 4;
  }
}
.h1__lH3Xr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 24px;
  font-size: 50px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1__lH3Xr {
    font-size: 64px;
  }
}
.accordion:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
}
.accordionItem__abe5H:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-family: "Outfit", sans-serif;
}
.text__yWci0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__tp4Jk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.accordionItem__iglHj:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__kuTj8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__rswhJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-bottom: 0px;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.accordionItem__rLoj0:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__nbomy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  padding-left: 0px;
  min-width: 0;
}
.text__cPq {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  text-overflow: clip !important;
  text-decoration-line: none !important;
  user-select: text;
  white-space: pre-wrap;
  letter-spacing: normal;
  line-height: 1.5;
  text-transform: none;
  text-align: left;
  color: #535353;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-family: "Outfit", sans-serif;
}
.link___5Aysb {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #0070f3;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.link__hg0H7 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #0070f3;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.accordionItem__pOUli:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__gbhS1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__edZkN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.section__h0Hxi {
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section__h0Hxi > * {
  grid-column: 4;
}
@media (min-width: 768px) {
  .section__h0Hxi {
    justify-self: center;
  }
}
@media (min-width: 768px) {
  .section__h0Hxi > * {
    grid-column: 4;
  }
}
.h1__xcrNh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-bottom: 24px;
  font-size: 64px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-transform: none;
  line-height: 1;
  letter-spacing: -1px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .h1__xcrNh {
    font-size: 64px;
    justify-self: center;
  }
}
.freeBox__aaEyq {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__aaEyq > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__aaEyq > :global(.__wab_flex-container) > *,
.freeBox__aaEyq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aaEyq > :global(.__wab_flex-container) > picture > img,
.freeBox__aaEyq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
@media (min-width: 768px) {
  .freeBox__aaEyq {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .freeBox__aaEyq > :global(.__wab_flex-container) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-left: calc(0px - 10px);
    width: calc(100% + 10px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__aaEyq > :global(.__wab_flex-container) > *,
  .freeBox__aaEyq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__aaEyq > :global(.__wab_flex-container) > picture > img,
  .freeBox__aaEyq
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 10px;
    margin-top: 0px;
  }
}
.link__pHgLv {
  position: relative;
  display: flex;
}
@media (min-width: 768px) {
  .link__pHgLv {
    color: #1f1f1f;
  }
}
.button2__nbbFh:global(.__wab_instance) {
  max-width: 100%;
}
.svg__rhGJg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__o6KS {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #000000;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
@media (min-width: 768px) {
  .text__o6KS {
    color: #000000;
  }
}
.svg__mtct2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.link__dVyNy {
  position: relative;
  display: flex;
}
.button2__cho6T:global(.__wab_instance) {
  max-width: 100%;
}
.svg__nVTkN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__r0UNy {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #000000;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
@media (min-width: 768px) {
  .text__r0UNy {
    color: #000000;
  }
}
.svg__xVtUp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__gZXav {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #1f1f1f;
  justify-self: flex-start;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 8px 18px 8px 14px;
}
@media (min-width: 768px) {
  .freeBox__gZXav {
    margin-top: 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-self: flex-start;
    min-width: 0;
    padding: 0px 260px 0px 250px;
  }
}
.text__aWIqQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 0.7em;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__aWIqQ {
    margin-top: 0px;
    margin-bottom: 0px;
    align-self: center;
    padding-left: 10px;
  }
}
.link___5Nt28 {
  position: relative;
  display: flex;
}
.svg__pvRwF {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link__ht5O2 {
  position: relative;
  display: flex;
}
.svg__dm3Z8 {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
@media (min-width: 768px) {
  .svg__dm3Z8 {
    display: none;
  }
}
.link__dRpnT {
  position: relative;
  display: flex;
}
.svg__zJfQi {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link__aDbc6 {
  position: relative;
  display: flex;
}
.svg__rVkwO {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.link__cc68P {
  position: relative;
  display: flex;
}
.svg__l9QfP {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
@media (min-width: 768px) {
  .svg__l9QfP {
    margin-right: 10px;
  }
}
